
import  {defineComponent} from 'vue';
import { UserController } from '@/controller';
import { PageHeader, Snackbar  } from '../../components/';

export default defineComponent({
    name: "ConfirmEmail",
    components: { Snackbar, PageHeader },
    data(){
        return{
            confirmed: false,
            alreadyconfirmed: false,
            icons: {
                loader: '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 38" style="enable-background:new 0 0 38 38;" xml:space="preserve"><style type="text/css">.st0{fill:none;stroke:#B9C7D1;stroke-width:2;stroke-opacity:0.5;}.st1{fill:none;stroke:#163D64;stroke-width:2;}</style><g><g transform="translate(1 1)"><circle class="st0" cx="18" cy="18" r="18"/><path class="st1" d="M36,18c0-9.9-8.1-18-18-18"><animateTransform  accumulate="none" additive="replace" attributeName="transform" calcMode="linear" dur="1s" fill="remove" from="0 18 18" repeatCount="indefinite" restart="always" to="360 18 18" type="rotate"></animateTransform></path></g></g></svg>',
                logo: '<?xml version="1.0" encoding="utf-8"?><!-- Generator: Adobe Illustrator 26.5.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  --><svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 997.7 939.3" enable-background="new 0 0 997.7 939.3" xml:space="preserve"><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M572.9,902.7L283.5,194.4C201.6,361.1,119.7,527.8,37.7,694.5"/><path fill="none" stroke="#163D64" stroke-width="50" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M960,744.9L670.6,36.6c-82,166.7-163.9,333.4-245.9,500.1"/></svg>',
            },
            snackbar: {
                show: false,
                text: '',
                error: false
            },
            responseState: '',
            countdown: 0,
            unhandledError: false
        }
    },
    created() {
        (document.getElementById('app') as HTMLElement).scrollIntoView({ behavior: 'smooth' });
        if (this.$route.params.id) {
            this.fetchConfirmationLink(this.$route.params.id);
        }
    },
    methods: {
        handleShowSnackbar(text:string, error : boolean){
            this.snackbar.show = true
            this.snackbar.text = text
            this.snackbar.error = error

            setTimeout(() => {
                this.snackbar.show = false
           }, 5000)
        },
        async fetchConfirmationLink(hash:any){
            let postData = {
                hash: hash
            }
            let res = await UserController.confirmUserMail(postData)
            
            if(res.data.success){
                this.confirmed = true
                this.responseState = 'success'
            } else {
                this.handleShowSnackbar(this.$t(res.data.errors[0].message), true)

                if(res.data.errors[0].field == 'alreadyconfirmed'){
                    this.alreadyconfirmed = true
                    this.redirectToLogin()
                } else if(res.data.errors[0].message == "error.error"){
                    this.unhandledError = true
                    this.redirectToLogin()
                }
                this.responseState = 'failed'
            }
        },
        redirectToLogin(){
            var self = this;
            this.countdown = 5;

            const countDownInterval = setInterval(function(){
                if(self.countdown > 0){
                    self.countdown--
                } else{
                    self.$router.push('/login')
                
                    clearInterval(countDownInterval) 
                }
            }, 1000)
        }
    }
});
